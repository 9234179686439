.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-text {
  text-align: center;
}

.loading-text span {
  display: inline-block;
  margin: 0 5px;
}

.loading-text span {
  filter: blur(0px);
  animation: blur-text 1.2s 0s infinite linear alternate;
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
}
